import styled from 'styled-components';
import {
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  SpacingSizes,
} from '../../../clarity';
import { to } from '../../styles/constants'
import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'

export const ContainerLeftBottomShadowMobile = styled.div`
  max-width: 762px;
  height: 163px;
  padding: 0 ${SpacingSizes.xl};
  box-shadow:  ${(props: any) => (`-12px 12px ${props.shadowColor}`)};
  background-color: ${GENERAL_COLOR_VALUES[GeneralColorNames.worthy_white]};

  @media ${to.bpB} {
    margin: 0 ${SpacingSizes.smd};
    height: auto;
    padding: ${SpacingSizes.md} ${SpacingSizes.smd};
    box-shadow:  ${(props: any) => (`-6px 6px ${props.shadowColor}`)};
  }
`

export const ContainerLeftBottomShadow = fadeUpWrapper(ContainerLeftBottomShadowMobile)
