import React from 'react'
import styled from 'styled-components'
import {
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  SpacingSizes,
  LineHeights,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
} from '../../../clarity'

import { fadeUpWrapper } from '../../animations/ScrollFadeUpAnimation'

import { size, to } from '../../styles/constants'
import common from '../../styles/common.module.css'
import { BlueWord } from '../../styles/styled/commonText'

// TODO - check if needs a changes after we implement our image solution (gatsby-image?)
import sameBoat from '../../images/homepage/same_boat.svg'
import personalCare from '../../images/homepage/personal_care.svg'
import professionalNetwork from '../../images/homepage/professional_network.svg'
import diamondLinesBG from '../../images/homepage/what_makes_us_special_bg.svg'
import { useBP } from '../../data/objects/breakpoints'
import { renderOnMount } from '../forceUpdate'

const sections = [
  {
    key: 'same_side',
    title: 'We\'re On Your Side',
    icon: sameBoat,
    iconSize: { w: 118, h: 75 },
    description: 'Our fees are respective to your earnings. Which means we only get paid when you do, and when you get more, we get more.',
  },
  {
    key: 'personal_care',
    title: 'Personal Auction Managers',
    icon: personalCare,
    iconSize: { w: 121, h: 75 },
    description: 'Your auction manager will advise you, support you and make sure you have a great selling experience.',
  },
  {
    key: 'professional_network',
    title: 'Professional Buyer Network',
    icon: professionalNetwork,
    iconSize: { w: 106, h: 75 },
    description: 'We have over 1K professional buyers who know the true value of jewelry. They compete for your piece, getting you the most money.',
  },
]

const Section = styled.div`

  color: ${GENERAL_COLOR_VALUES[GeneralColorNames.black_main]};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    background: 100% 50% no-repeat url(${diamondLinesBG});
    background-size: 100%;
    top: 125px;
    bottom: auto;
    height: 650px;
    width: 370px;
    right: 0;
    left: auto;
  }

  @media ${to.bpB} {
    &:before {
    content: '';
    position: absolute;
    display: block;
    z-index: -1;
    background: 0% 100% no-repeat url(${diamondLinesBG});
    background-size: 111%;
    top: 165px;
    bottom: auto;
    height: 405px;
    width: 213px;
    right: 0;
    left: auto;
  }
  }
`

const DescriptionBoxWrapper = styled.div`
  padding: 0 ${SpacingSizes.xsm};
  @media ${to.bpB} {
    padding: 0;
    &:not(:last-of-type){
      margin-bottom: ${SpacingSizes.xl};
    }
  }
`
const DescriptionBoxContent = fadeUpWrapper(styled.div`
  max-width: 377px;
`)

const SectionTitleWrapper = styled.div`
  margin: ${SpacingSizes.xxl} 0 ${SpacingSizes.xl};

  @media ${to.bpB} {
    margin: ${SpacingSizes.xxl} ${SpacingSizes.smd} ${SpacingSizes.xl};
  }
`

const SectionTitle = fadeUpWrapper(styled.h2`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_36]};
  line-height: ${LineHeights[54]};
`)

const BoxTitle = styled.div`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
  margin: ${SpacingSizes.smd} 0 ${SpacingSizes.sm};
  @media ${to.bpB} {
    margin: ${SpacingSizes.smd} 0 ${SpacingSizes.sm};
  }
`
const BoxIcon = styled.img((props: any) => ({
  src: props.src,
}))

const BoxText = styled.div`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.roboto_18]};
  line-height: ${LineHeights[30]};
`
const DescriptionBoxesContainer = styled.div`
  padding: 0 ${SpacingSizes.xsm};
  max-width: ${size.e};
  margin-bottom: ${SpacingSizes.xxl};

  @media ${to.bpB} {
    padding: 0 ${SpacingSizes.smd};
  }
`

const WhatMakesUsSpecial = () => {
  const { fitsB } = useBP().get()
  const renderKey = renderOnMount()
  return (
    <Section
      className={[
        common.w100,
        common.dFlex,
        common.flexCol,
        common.margin0auto,
        common.flexAlignCenter,
        common.flexJustifyCenter,
        common.textCenter,
      ]}
      key={renderKey}
    >
      <SectionTitleWrapper>
        <SectionTitle>
          What Makes Worthy
          {' '}
          <BlueWord>Special</BlueWord>
          ?
        </SectionTitle>
      </SectionTitleWrapper>
      <DescriptionBoxesContainer
        className={[
          common.dFlex,
          fitsB ? common.flexCol : common.flexRow,
          common.w100,
          common.flexJustifyAround,
        ]}
      >
        {
          sections.map((section:any) => (
            <DescriptionBoxWrapper
              key={section.key}
              className={[
                common.dFlex,
                common.flexCol,
                common.flexJustifyFlexStart,
                common.textLeft,
                common.flexAlignCenter,
              ]}
            >
              <DescriptionBoxContent
                className={fitsB ? [common.textCenter, common.margin0auto] : []}
              >
                <BoxIcon
                  width={section.iconSize.w}
                  height={section.iconSize.h}
                  src={section.icon}
                  alt={`${section.title} icon`}
                />
                <BoxTitle>{section.title}</BoxTitle>
                <BoxText>{section.description}</BoxText>
              </DescriptionBoxContent>
            </DescriptionBoxWrapper>
          ))
        }
      </DescriptionBoxesContainer>
    </Section>
  )
}
export default WhatMakesUsSpecial
