import React from 'react';
import SubmissionSelectionMobile from './submissionSelectionMobile'
import SubmissionSelectionDesktop from './submissionSelectionDesktop'
import { useBP } from '../../../data/objects/breakpoints'
import { renderOnMount } from '../../forceUpdate'

const SubmissionSelection = () => {
  const renderKey = renderOnMount()
  return (
    <>
      {
        useBP().get().fitsA
          ? <SubmissionSelectionMobile key={renderKey} />
          : <SubmissionSelectionDesktop key={renderKey} />
      }
    </>
  )
};

export default SubmissionSelection;
