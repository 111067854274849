import React, { useEffect, useState } from 'react'
import Cookie from 'js-cookie'
import Layout from '../components/layout/layout'
import MobileLayout from '../components/layout/mobileLayout'
import SEO from '../components/layout/seo'
import TrustUs from '../components/homepage/trustPilotNLogos'
import SubmissionSelection from '../components/homepage/submissionSelectionSection/submissionSelectionSection'
import WhatWeDoDescription from '../components/homepage/whatWeDoDescription'
import WhatMakesUsSpecial from '../components/homepage/whatMakesUsSpecial'
import RecentDealsSection from '../components/homepage/recentDealsSection/RecentDealsSection'
import OurProcess from '../components/homepage/ourProcess'
import ReviewsSection from '../components/homepage/reviewsSection'
import FAQSection from '../components/homepage/faqSection/faqSection'
import { useBP } from '../data/objects/breakpoints'
import { renderOnMount } from '../components/forceUpdate'
import { TestimonialsSection } from '../components/homepage/testimonials/TestimonialsSection'
import { GA } from '../data/GA'

const pageForGAEvents = 'Homepage'

const IndexPage = () => {
  const [firstLoadSetting, setFirstLoadSetting] = useState(false)
  const metaDesc =
    'Sell diamond jewelry, rings & watches for the absolute most at Worthy.com. Featured in NYT, Forbes and US News. Trustworthy, fully insured online auctions.'
  const renderKey = renderOnMount()
  useEffect(() => {
    if (!firstLoadSetting) {
      const searchParams = new URLSearchParams(window.location.search)
      GA.utmData(searchParams.get('utm_medium'))
      Cookie.set('visited_new_welcome_experience', 'true', { domain: 'worthy.com', path: '/', expires: 30 })
      setFirstLoadSetting(true)
    }
  }, [])

  const main = (
    <>
      <SEO title="Sell Diamonds, Jewelry and Watches for the Best Price " description={metaDesc} />
      <SubmissionSelection />
      <TrustUs />
      <WhatWeDoDescription />
      <WhatMakesUsSpecial />
      <OurProcess />
      <RecentDealsSection />
      <TestimonialsSection />
      <ReviewsSection />
      <FAQSection />
    </>
  )

  return useBP().get().fitsB ? (
    <MobileLayout key={renderKey} page={pageForGAEvents}>
      {main}
    </MobileLayout>
  ) : (
    <Layout key={renderKey} page={pageForGAEvents} transparentHeader>
      {main}
    </Layout>
  )
}

export default IndexPage
