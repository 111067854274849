import React from 'react'
import styled from 'styled-components'
import {
  GENERAL_FONT_VALUES,
  GeneralFontNames,
  GENERAL_COLOR_VALUES,
  GeneralColorNames,
  SpacingSizes,
  LineHeights,
} from '../../../clarity'

import bgPattern from '../../images/homepage/company_vision_description_bg.svg'
import zigzag from '../../images/common/zigzagIconBlue.svg'
import { BackgroundSection } from '../commonStyled/sectionsWithBackground'
import {
  ContainerLeftBottomShadow,
  ContainerLeftBottomShadowMobile,
} from '../commonStyled/continaerLeftBottomShadow'
import { ZigZagIcon } from '../../styles/styled/commonStyled'
import common from '../../styles/common.module.css'
import { to } from '../../styles/constants'
import { useBP } from '../../data/objects/breakpoints'
import { renderOnMount } from '../forceUpdate'

const StyledText = styled.span`
  font: ${GENERAL_FONT_VALUES[GeneralFontNames.playfair_22]};
  margin-bottom: ${SpacingSizes.xs};
  line-height: ${LineHeights['36']};

  @media ${to.bpB} {
    margin-bottom: ${SpacingSizes.sm}
  }
`

const WhatWeDoDescription = () => {
  const { fitsB } = useBP().get()
  const renderKey = renderOnMount()
  const ShadowContainer = fitsB ? ContainerLeftBottomShadowMobile : ContainerLeftBottomShadow

  return (
    <BackgroundSection
      className={[
        common.dFlex,
        common.flexAlignCenter,
        common.flexJustifyCenter,
      ]}
      bgPattern={bgPattern}
      bgColor={GENERAL_COLOR_VALUES[GeneralColorNames.primary_200]}
    >
      <ShadowContainer
        className={[
          common.dFlex,
          common.flexAlignCenter,
          common.flexAlignSelfCenter,
          common.textCenter, common.flexCol,
          common.flexJustifyCenter,
          common.margin0auto,
        ]}
        shadowColor={GENERAL_COLOR_VALUES[GeneralColorNames.primary_400]}
        key={renderKey}
      >
        <StyledText>
          We use our expertise, technology and connections to get jewelry
          sellers the absolute most for their jewelry.
        </StyledText>
        <ZigZagIcon src={zigzag} alt="zig-zag" />
      </ShadowContainer>
    </BackgroundSection>
  )
}

export default WhatWeDoDescription
